import { useState, useEffect, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useApp } from "./useApp"
import { useSanity } from "./useSanity"

const query = graphql`
  query BRIDES {
    sanityBridesPage {
      brides: _rawBrides(resolveReferences: { maxDepth: 10 })
    }
  }
`

export const useBrides = () => {
  const {
    config: { settings },
  } = useApp()
  const { textNormaliser } = useSanity()
  const data = useStaticQuery(query)

  const brides = useMemo(
    () =>
      data?.sanityBridesPage?.brides.map(bride => ({
        ...bride,
        info: textNormaliser(bride.info),
        gownNames:
          bride.links
            ?.map(link =>
              link?.product?.tags
                ?.find(tag => tag.includes(settings.brides.gownFilterTag))
                ?.replace(settings.brides.gownFilterTag, "")
                .replace(settings.brides.gownKeywords, "")
                .trim()
            )
            .filter(gownName => !!gownName) || [],
      })) || [],
    [data?.sanityBridesPage?.brides]
  )

  const [filteredBrides, setFilteredBrides] = useState(brides)
  const [filters, setFilters] = useState(null)

  const handleFilters = value => {
    setFilters(value)
  }

  const handleResetFilters = () => {
    setFilters(null)
  }

  let availableGownFilters = brides
    .map(item => item.gownNames)
    .flat()
    .filter((value, index, self) => self.indexOf(value) === index)

  // availableGownFilters = brides.

  const availableVendors = brides
    .filter(bride => bride?.vendor?.title)
    .map(bride => (bride.vendor ? bride.vendor.title : ""))
    .filter((item, index, array) => array.indexOf(item) === index)
    .sort()
    .reverse()

  useEffect(() => {
    const filteredBrides = brides.filter(bride => 
      bride?.vendor?.title === filters 
      || bride?.gownNames.includes(filters) 
      || !filters )
    setFilteredBrides(filteredBrides)
  }, [filters])

  return {
    filteredBrides,
    handleResetFilters,
    availableVendors,
    filters,
    handleFilters,
    availableGownFilters,
  }
}
