import React, { Fragment } from "react"

import { withDropdownFilter } from "./withDropdownFilter"

import { Dropdown } from "../../Styled/Dropdown/Dropdown"

export const DropdownFilter = withDropdownFilter(
  ({ locales, availableGownFilters, filters, handleFilters}) => (
    <Fragment>
      <Dropdown
        key={"gown-filter"}
        label={filters.label ? filters.label : locales?.filterGown || "Gown"}
        name={filters.label}
        onChange={value => handleFilters(value)}
        options={availableGownFilters}
        value={filters.value}
        searchable={false}
        deselected={!filters.value}
        liteMode
      />
    </Fragment>
  )
)
