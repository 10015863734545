import React from "react"

import { withBridesGrid } from "./withBridesGrid"
import { BridesItem } from "../Item/BridesItem"
import { BridesPopup } from "../Popup/BridesPopup"
import { GridContainer, Items, Brides } from "../BridesStyles"

interface Props {
  items: any
  reverse: boolean
  setZoom: Function
  setZoomURL: Function
  zoom: boolean
  zoomURL: string
}

export const BridesGrid = withBridesGrid(({ items, reverse, setZoom, setZoomURL, zoom, zoomURL }: Props) => (
  <GridContainer>
    <Items>
      {items.length > 2 ? (
        <>
          {reverse ? (
            <Brides sticky width={`1/2`}>
              <Items>
                <BridesItem key={items[0].id} item={items[0]} ratio={0.7065} setZoom={setZoom} setZoomURL={setZoomURL} />
              </Items>
            </Brides>
          ) : null}

          <Brides width={`1/2`}>
            <Items>
              <>
                {items.slice(1).map(item => (
                  <BridesItem key={item.id} item={item} setZoom={setZoom} setZoomURL={setZoomURL} width={`1/2`} />
                ))}
              </>
            </Items>
          </Brides>

          {!reverse ? (
            <Brides sticky width={`1/2`}>
              <Items>
                <BridesItem key={items[0].id} item={items[0]} ratio={0.7065} setZoom={setZoom} setZoomURL={setZoomURL} />
              </Items>
            </Brides>
          ) : null}
        </>
      ) : (
        items.map(item => <BridesItem key={item.id} item={item} setZoom={setZoom} setZoomURL={setZoomURL} width={`1/4`} />)
      )}
    </Items>

    <BridesPopup setZoom={setZoom} zoom={zoom} zoomURL={zoomURL} />
  </GridContainer>
))
