import React from "react"

import { useLanguage } from "@hooks/useLanguage"

export const withBridesFilters = Component => ({ name = `BridesFilters`, ...props }) => {
  const locales = useLanguage(`brides`)

  Component.displayName = name
  return <Component {...props} locales={locales} />
}
