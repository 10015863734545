import React from "react"

import { useApp } from "@hooks/useApp"
import { useImage } from "@hooks/useImage"
import { useLanguage } from "@hooks/useLanguage"

export const withBridesItem = Component => ({ name = `BridesItem`, item, setZoom, ratio, setZoomURL, width }) => {
  const {
    config: {
      settings: { lists },
    },
  } = useApp()
  const { getFluidImage } = useImage()
  const locales = useLanguage(`brides`)

  const image = getFluidImage(item.image, { maxWidth: 1000 })

  const handleZoom = () => {
    setZoomURL(null)
    setZoom(true)
    setZoomURL(image?.src)
  }

  const list = lists[1].key

  Component.displayName = name
  return <Component handleZoom={handleZoom} image={image} item={item} list={list} locales={locales} ratio={ratio} width={width} />
}
