import React from "react"
import { navigate } from "gatsby"

import { withBridesItem } from "./withBridesItem"
import { Image } from "../../Image/Image"
import { WishlistButton } from "../../Wishlist/Button/WishlistButton"
import { WishlistPin } from "../../Wishlist/Pin/WishlistPin"
import { P } from "../../Styled/Text"
import { Card, Content, ImageWrapper, ImageOverlay, StyledButton, Details, Wishlist } from "../BridesStyles"

interface Props {
  handleZoom: Function
  image: any
  item: any
  list: any
  locales: any
  ratio: number
  width: string
}

export const BridesItem = withBridesItem(({ handleZoom, image, list, locales, item, ratio, width }: Props) => (
  <Card width={width} className={`group`}>
    <Content>
      <StyledButton onClick={() => (item?.link?.url ? navigate(item.link.url) : handleZoom(image))} title={item?.link?.title || item?.image?.alt}>
        <ImageWrapper>
          <Image alt={item?.image?.alt || `${item.name} image`} src={image} ratio={ratio ? ratio : 0.7} />
          <ImageOverlay />
        </ImageWrapper>

        <Details>
          {item.name ? <P>{`${locales.name} ${item.name}`}</P> : null}
          {item.dress && item?.links?.length ? (
            <a
              onClick={e => e.stopPropagation()}
              className="hover:opacity-75 hover:underline pointer-events-auto text-sm font-body font-normal tracking-wide leading-looser"
              href={`${item?.links[0]?.product?.url}/${item?.links[0]?.product?.title.toLowerCase()}`}
            >
              {item.dress}
            </a>
          ) : item.dress ? (
            <P>{item.dress}</P>
          ) : null}
          {item.info ? <P>{`${locales.info} ${item.info}`}</P> : null}
        </Details>
      </StyledButton>

      <Wishlist>
        <WishlistButton
          item={{
            ...item,
            image,
            title: item.name,
            id: item.key || item.id,
          }}
          wishlistName={list}
        />
        <WishlistPin item={{ ...item, src: item.image.src }} />
      </Wishlist>
    </Content>
  </Card>
))
