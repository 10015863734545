import React from "react"

import { useLanguage } from "@hooks/useLanguage"

export const withDropdownFilter = Component => ({
  name = `DropdownFilter`,
  filters: rawFilters,
  availableGownFilters: rawAvailableGownFilters,
  ...props
}) => {
  const locales = useLanguage(`brides`)

  const activeFilter = rawAvailableGownFilters.includes(rawFilters)
  const filters = {
    label: activeFilter ? rawFilters : locales?.filterGown || "Gown",
    value: activeFilter ? rawFilters : null,
  }
  const availableGownFilters = rawAvailableGownFilters.map(filter => ({ value: filter, label: filter }))
  availableGownFilters.unshift({ value: null, label: "All" })

  Component.displayName = name
  return <Component {...props} filters={filters} availableGownFilters={availableGownFilters} locales={locales} />
}
