import React from "react"

import { withBrides } from "./withBrides"
import { HeroBanner } from "../Sections/HeroBanner/HeroBanner"
import { BridesFilters } from "./Filters/BridesFilters"
import { BridesGrid } from "./Grid/BridesGrid"
import { StyledH2, StyledContainer, HeroWrapper, SeoDescription } from "./BridesStyles"

interface Props {
  availableVendors: any
  banners: any
  content: any
  filters: Array<any>
  filteredItems: Array<any>
  handleFilters: Function
  handleResetFilters: Function
  availableGownFilters: Array<string>
  hero: any
}

const OptionalSeoDescription = ({ label, as }: { label: string; as: string }): ReactElement =>
  label ? <SeoDescription as={as}>{`${label}`}</SeoDescription> : null

export const Brides = withBrides(
  ({ availableVendors, banners, content, filteredItems, filters, handleFilters, handleResetFilters, hero, availableGownFilters }: Props) => (
    <>
      {hero && <HeroBanner section={hero} condensed={`true`} gradient={`true`} />}

      <StyledContainer>
        <StyledH2>{content.title}</StyledH2>
        <OptionalSeoDescription as={`p`} label={content.description} />
      </StyledContainer>

      <BridesFilters
        availableVendors={availableVendors}
        filters={filters}
        handleFilters={handleFilters}
        handleResetFilters={handleResetFilters}
        availableGownFilters={availableGownFilters}
      />

      {filteredItems[0].length ? <BridesGrid items={filteredItems[0]} /> : null}

      {filteredItems[1].length > 0 && (
        <>
          {banners[0] && (
            <HeroWrapper condensed>
              <HeroBanner section={banners[0]} />
            </HeroWrapper>
          )}
          <BridesGrid items={filteredItems[1]} reverse />
        </>
      )}

      {banners[1] && (
        <HeroWrapper condensed>
          <HeroBanner section={banners[1]} />
        </HeroWrapper>
      )}
    </>
  )
)
