import React from "react"

import { useApp } from "@hooks/useApp"
import { useBrides } from "@hooks/useBrides"
import { useSanity } from "@hooks/useSanity"
import { useSection } from "@hooks/useSection"

export const withBrides = Component => ({ name = `Brides`, page }) => {
  const { setInvertedTheme, setTransparentTheme } = useApp()
  const { heroNormaliser } = useSanity()
  const { transformSection } = useSection()

  const content = {
    ...page,
    banners: [heroNormaliser(page?.heroBanner), heroNormaliser(page?.heroBanner2), heroNormaliser(page?.heroBanner3)],
  }

  const { filteredBrides, handleResetFilters, availableVendors, availableGownFilters, filters, handleFilters } = useBrides()

  if (content?.settings) {
    setInvertedTheme(content.settings.dark)
    setTransparentTheme(content.settings.transparent)
  }

  const min = 9
  const mean = filteredBrides.length > min ? Math.ceil(filteredBrides.length / 2) : min
  const filteredItems = [filteredBrides.slice(0, mean), filteredBrides.slice(mean)]

  const hero = transformSection(content?.banners[0])

  const banners = [
    content.banners?.[1] && content?.banners?.[1].content?.length > 0 ? transformSection(content.banners[1], 1) : null,
    content.banners?.[2] && content?.banners?.[2].content?.length > 0 ? transformSection(content.banners[2], 2) : null,
  ]

  Component.displayName = name
  return (
    <Component
      availableVendors={availableVendors}
      availableGownFilters={availableGownFilters}
      banners={banners}
      content={content}
      filteredItems={filteredItems}
      filters={filters}
      handleFilters={handleFilters}
      handleResetFilters={handleResetFilters}
      hero={hero}
    />
  )
}
